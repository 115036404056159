<template>
  <div class="app-container">
    <div class="contractBox">
      <!-- <div class="title">
        买卖合同
        <div class="downLoad">下载合同</div>
      </div> -->

      <!-- 拍卖编号 -->
      <!-- <div class="applicationNum">
        项目名称： DCZJP1854467
      </div> -->
      <!-- <div class="applicationNum">
        合同编号： DCZJP1854467
      </div> -->

      <!-- 合同时间，编号，在线地址 -->
      <div class="contract">
        <div class="time">合同签署时间：{{ datas.contractTime }}</div>

        <div class="contractNo">合同编号：{{ datas.customBizNum }}</div>

        <!-- <div class="url">
          在线合同地址：XXXXXXXXXXXXXX
        </div> -->
      </div>

      <!-- 合同 -->
      <div class="detail">
        <pdf v-for="item in pageTotal" :src="fileURL" :key="item" :page="item">
        </pdf>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { viewContract } from "@/api/workSpace";
export default {
  components: { pdf },
  data() {
    return {
      pageTotal: null,
      fileURL: "",
      datas: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // init() {
    //   viewContract({ signFlowId: this.$route.query.signFlowId }).then((res) => {
    //     if (res.code == 200) {
    //       this.fileURL = res.data.downloadUrl;
    //       this.datas = res.data;
    //       // 多页pdf的src中不能直接使用后端获取的pdf地址 否则会按页数请求多次数据
    //       // 需要使用下述方法的返回值作为url
    //       this.fileURL = pdf.createLoadingTask(this.fileURL);
    //       // 获取页码
    //       this.fileURL.promise
    //         .then((pdf) => (this.pageTotal = pdf.numPages))
    //         .catch((error) => {});
    //     }
    //   });
    // },
    init() {
      viewContract({ signFlowId: this.$route.query.signFlowId }).then((res) => {
        if (res.code === 200) {
          this.fileURL = res.data.downloadUrl;
          this.datas = res.data;

          // 检查 pdf 是否正确导入和定义
          if (typeof pdf !== "undefined") {
            console.log(111111111);
            
            // 使用 createLoadingTask 创建任务
            const loadingTask = pdf.createLoadingTask(this.fileURL);

            loadingTask.promise
              .then((pdfDocumentProxy) => {
                // 获取页码
                this.pageTotal = pdfDocumentProxy.numPages;
              })
              .catch((error) => {
                console.error("Error loading PDF:", error);
              });

            // 设置 PDF Viewer 的 src 为文件路径
            this.fileURL = this.fileURL;
          } else {
            console.error("pdf object is not defined.");
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app-container {
  background-color: #fafafa;
  min-height: 90vh;
  .contractBox {
    width: 1200px;
    // height: 100vh;
    margin: 0 auto;
    padding: 40px 0;
    box-sizing: border-box;

    .title {
      font-weight: 500;
      font-size: 28px;
      color: #252525;
      display: flex;
      justify-content: space-between;
      text-align: center;
      .downLoad {
        width: 120px;
        height: 40px;
        background: #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
      }
    }
    .applicationNum {
      font-weight: 500;
      font-size: 22px;
      color: #4a4a4a;
      margin-top: 20px;
    }
    .contract {
      font-weight: 400;
      font-size: 14px;
      color: #000000d9;
      margin-top: 40px;
    }
    .detail {
      box-shadow: 0 3px 6px 1px #0000000f;
      // padding-bottom: 40px;
      margin-top: 40px;
      box-sizing: border-box;
      .content {
        height: 500px;
        background-color: #ccc;
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
        .confirm {
          width: 120px;
          height: 40px;
          background: #0dac65;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
          margin-right: 20px;
        }
        .noConfirm {
          width: 120px;
          height: 40px;
          border: 1px solid #0dac65;
          font-weight: 400;
          font-size: 16px;
          color: #0dac65;
          text-align: center;
          line-height: 40px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
